<template>
  <section class="cms-section">
    <h2 v-if="!!title" data-testid="title" class="mb-4">{{ title }}</h2>
    <p v-if="!!body" data-testid="body" class="mb-4">{{ body }}</p>
    <div class="cms-section row">
      <div
        v-for="(item, index) in cards.cardFinance"
        :key="`${item.title}-${index}`"
        class="cms-section-item col-12 col-md-12"
      >
        <HomeCmsFinanceCard
          :data="item"
          :badge="badgeText"
          :tracking-options="trackingOptions"
        />
      </div>
    </div>
    <div class="cms-section-grid row">
      <div
        v-for="(item, index) in cards.card"
        :key="`${item.title}-${index}`"
        class="cms-section-item col-12 col-md-6"
      >
        <HomeCmsCard
          :data="item"
          :badge="badgeText"
          :tracking-options="trackingOptions"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import {
  CmsCalculatorItem,
  CmsOffer,
  CmsTrendingItem
} from '~/types/cms/contentModel'
import { ECmsSectionType } from '~/types/cms/contentModelEnum'
import { EConditionSlug } from '~/types/vehicle'

const isCmsOffer = (
  data: CmsOffer | CmsTrendingItem | CmsCalculatorItem
): data is CmsOffer => Object.hasOwnProperty.call(data, 'isFinanceOffer')

export default {
  props: {
    data: {
      type: Array as PropType<
        (CmsOffer | CmsTrendingItem | CmsCalculatorItem)[]
      >,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: () => ''
    },
    body: {
      type: String,
      required: false,
      default: () => ''
    },
    sectionType: {
      type: String,
      required: true
    }
  },

  setup(props) {
    const route = useRoute()
    const isNew = route.params.condition === EConditionSlug.New

    const slicedDataItems = computed(() => props.data?.slice(0, 7) || [])

    const badgeText = computed(() => {
      return props.sectionType === ECmsSectionType.OFFERS ? 'Offer' : undefined
    })

    const trackingOptions = computed(() => {
      return props.sectionType === ECmsSectionType.SIMILAR_MODELS
        ? { category: 'similar models' }
        : undefined
    })

    const cards = computed(() =>
      slicedDataItems.value.reduce<{
        cardFinance: CmsOffer[]
        card: (CmsOffer | CmsTrendingItem | CmsCalculatorItem)[]
      }>(
        (prev, current) => {
          if (isCmsOffer(current) && current.isFinanceOffer) {
            return {
              ...prev,
              cardFinance: [...prev.cardFinance, current]
            }
          }

          return {
            ...prev,
            card: [...prev.card, current]
          }
        },
        {
          cardFinance: [],
          card: []
        }
      )
    )

    return { isNew, slicedDataItems, badgeText, trackingOptions, cards }
  }
}
</script>

<style lang="scss" scoped>
.cms-section {
  .cms-section-grid {
    margin-bottom: calc(
      -#{rem(40)} - #{rem(16)} - #{rem(8)}
    ); //cms-section-item - card-content - col

    .cms-section-item {
      margin-bottom: rem(40);
    }
  }
}
</style>
