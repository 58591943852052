<template>
  <OsCard
    ref="offerCard"
    class="finance-offer-card mb-4"
    :type="ECardType.PLAIN"
  >
    <div class="row">
      <div class="col-12 col-md-4">
        <component
          :is="`${hasCTA ? 'button' : 'div'}`"
          :class="hasCTA && 'finance-offer-card__button'"
          @click="hasCTA ? goTo() : () => null"
        >
          <template v-if="hasImage">
            <BadgeItem
              v-if="badge"
              class="finance-offer-card__finance-badge"
              floating
              dark
            >
              {{ badge }}
            </BadgeItem>

            <ImagePicture
              class="image"
              format="webp,png"
              sizes="sm:320px md:400px"
              provider="contentful"
              :width="data.image?.file.details.image.width"
              :height="data.image?.file.details.image.height"
              :src="data.image?.file.url"
              loading="lazy"
              :title="data.title"
              :alt="data.title"
            />
          </template>
        </component>
      </div>
      <div class="col-12 col-md-8">
        <div class="row">
          <div class="col-12 finance-offer-card__finance-title">
            <component
              :is="`${hasCTA ? 'button' : 'div'}`"
              :class="hasCTA && 'finance-offer-card__button'"
              @click="hasCTA ? goTo() : () => null"
            >
              <OsCardTitle class="pt-0">{{ data.title }}</OsCardTitle>
            </component>
          </div>
        </div>
        <div class="row mt-0">
          <div
            v-if="data.subtitle"
            class="col-12 finance-offer-card__finance-subtitle"
          >
            <OsRichTextRenderer name="offerCard" :content="data.subtitle" />
          </div>
        </div>
        <div class="row">
          <div
            v-for="(item, index) in financeData"
            :key="`${item.value}-${index}`"
            class="col-6 col-md-3"
          >
            <div class="text-base text-500-gray">
              {{ item.key }}
            </div>
            <div class="text-lg bold">
              {{ item.value }}
            </div>
          </div>
        </div>
        <div class="row text-gray-500">
          <div class="col-12">
            <OsCardContent v-if="data.additionalText">
              <OsRichTextRenderer
                name="offerCard"
                :content="data.additionalText"
              />
            </OsCardContent>
          </div>
        </div>
      </div>
    </div>
  </OsCard>
</template>

<script lang="ts">
import { CmsOffer } from '~/types/cms/contentModel'
import { ECardType } from '~/types/components/card'

type FinanceData = Pick<
  CmsOffer,
  | 'monthlyPaymentsOf'
  | 'fixedInterestRate'
  | 'optionalPurchasePayment'
  | 'totalAmountPayable'
  | 'purchaseActivationFee'
  | 'annualMileageLimit'
  | 'excessMileageCharge'
>

export default {
  props: {
    data: {
      type: Object as PropType<CmsOffer>,
      required: true
    },
    badge: {
      type: String,
      required: false,
      default: null
    },
    trackingOptions: {
      type: Object,
      default: null,
      required: false
    }
  },

  setup(props) {
    const offerCard = ref<null | HTMLElement>(null)
    const { $dataLayer } = useNuxtApp()
    const config = useRuntimeConfig()
    const { openURL } = useOpenURL()

    const hasImage = computed(() => !!props.data.image?.file.url)
    const hasCTA = computed<boolean>(() => !!props.data.targetUrl)

    const financeDataTitle: Record<keyof FinanceData, string> = {
      monthlyPaymentsOf: 'Monthly Payments of',
      fixedInterestRate: 'Fixed Interest Rate',
      optionalPurchasePayment: 'Optional Purchase Payment',
      totalAmountPayable: 'Total Amount Payable',
      purchaseActivationFee: 'Purchase Activation Fee',
      annualMileageLimit: 'Annual Mileage Limit',
      excessMileageCharge: 'Excess Mileage Charge'
    }

    const financeData = computed(() => {
      const numberOfMonths = props.data.numberOfMonths
      return (
        [
          'monthlyPaymentsOf',
          'fixedInterestRate',
          'optionalPurchasePayment',
          'totalAmountPayable',
          'purchaseActivationFee',
          'annualMileageLimit',
          'excessMileageCharge'
        ] as (keyof FinanceData)[]
      )
        .filter((prop) => !!props.data[prop])
        .map((prop) => ({
          key:
            prop === 'monthlyPaymentsOf'
              ? `${numberOfMonths} ${financeDataTitle[prop]}`
              : financeDataTitle[prop],
          value: props.data[prop]
        }))
    })

    const openNewWindow = computed<boolean>(
      () => !(props.data.targetUrl as string).startsWith(config.public.baseUrl)
    )

    const trackClickEvent = () => {
      $dataLayer.linkClick({
        category: 'promo',
        action: props.data.title,
        ...props.trackingOptions
      })
    }

    const goTo = () => {
      trackClickEvent()

      openURL(props.data.openInNewWindow, props.data.targetUrl)
    }

    const { stop } = useIntersectionObserver(
      offerCard,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          $dataLayer.eecImpression({
            action: 'promo impression'
          })
          stop()
        }
      }
    )

    return {
      ECardType,
      hasImage,
      goTo,
      hasCTA,
      openNewWindow,
      trackClickEvent,
      offerCard,
      financeData
    }
  }
}
</script>

<style lang="scss" scoped>
.finance-offer-card {
  position: relative;

  .pt-0 {
    padding-top: 0;
  }

  &__button {
    position: relative;
    padding: 0;
    text-align: left;
    cursor: pointer;
    width: 100%;
  }

  &__finance-title {
    padding-bottom: 0;
  }

  &__finance-subtitle:after {
    content: '';
    background: #d7d7d7;
    height: 1px;
    display: block;
    top: 16px;
    position: relative;
    margin-bottom: 16px;
  }
}
</style>
