<template>
  <OsCard ref="offerCard" class="offer-card" :type="ECardType.PLAIN">
    <component
      :is="`${!hasCtaButton && hasCTA ? 'button' : 'div'}`"
      :class="!hasCtaButton && hasCTA && 'offer-card__button'"
      @click="!hasCtaButton && hasCTA ? goTo() : () => null"
    >
      <template v-if="hasImage">
        <BadgeItem v-if="badge" floating dark>{{ badge }}</BadgeItem>
        <ImagePicture
          provider="contentful"
          format="webp,png"
          :width="295"
          :height="175"
          :alt="data?.image?.title"
          :src="data?.image?.file.url.replace('https:', '')"
          loading="lazy"
          sizes="100vw md:400px"
        />
      </template>
      <OsCardTitle v-if="data.title">{{ data.title }}</OsCardTitle>
    </component>
    <OsCardContent v-if="data.subtitle" class="pl-0 pr-0">
      <OsRichTextRenderer name="offerCard" :content="data.subtitle" />
    </OsCardContent>

    <OsButton
      v-if="hasCtaButton && isCmsCalculator(data)"
      is-primary
      :link="data.targetUrl"
      class="mt-2"
      @click.stop="trackClickEvent"
    >
      <OsIcon :name="data.ctaIconName" />
      {{ data.ctaCopy }}
    </OsButton>
  </OsCard>
</template>

<script lang="ts">
import {
  CmsCalculatorItem,
  CmsOffer,
  CmsTrendingItem
} from '~/types/cms/contentModel'
import { ECardType } from '~/types/components/card'

const isCmsCalculator = (
  data: CmsOffer | CmsTrendingItem | CmsCalculatorItem
): data is CmsCalculatorItem =>
  Object.hasOwnProperty.call(data, 'ctaIconName') &&
  (data as CmsCalculatorItem).ctaIconName === 'calculator'

export default {
  props: {
    data: {
      type: Object as PropType<CmsOffer | CmsTrendingItem | CmsCalculatorItem>,
      required: true
    },
    badge: {
      type: String,
      required: false,
      default: null
    },
    trackingOptions: {
      type: Object,
      default: null,
      required: false
    }
  },

  setup(props) {
    const offerCard = ref<null | HTMLElement>(null)
    const { $dataLayer } = useNuxtApp()
    const { openURL } = useOpenURL()

    const hasImage = computed(() => !!props.data.image?.file?.url)
    const hasCTA = computed<boolean>(() => props.data.targetUrl !== undefined)

    const hasCtaButton = computed<boolean>(
      () =>
        hasCTA.value &&
        isCmsCalculator(props.data) &&
        !!props.data.ctaCopy &&
        !!props.data.ctaIconName
    )

    const trackClickEvent = () => {
      $dataLayer.linkClick({
        category: 'promo',
        action: props.data.title,
        ...props.trackingOptions
      })
    }

    const goTo = () => {
      const openInNewWindow = isCmsCalculator(props.data)
        ? false
        : !!props.data.openInNewWindow

      openURL(openInNewWindow, props.data.targetUrl)
      trackClickEvent()
    }

    const { stop } = useIntersectionObserver(
      offerCard,
      ([{ isIntersecting }]) => {
        if (isIntersecting) {
          $dataLayer.eecImpression({
            action: 'promo impression'
          })
          stop()
        }
      }
    )

    return {
      ECardType,
      hasImage,
      goTo,
      hasCTA,
      hasCtaButton,
      trackClickEvent,
      offerCard,
      isCmsCalculator
    }
  }
}
</script>

<style lang="scss" scoped>
.offer-card {
  position: relative;

  &__button {
    padding: 0;
    text-align: left;
    cursor: pointer;
    width: 100%;
  }
}
</style>
